import { assertSuccess, paramsParser } from "../core";
import axios from "../core/axios";
import type {
  CollectionResult,
  GenericListParams,
  Relationship,
} from "../types";
import type { EntityLogEntity } from "../types/log";
import { Headers } from "./generic";

export type FindEntitiyLogParams = GenericListParams & {
  order?: Record<"createdAt", "asc" | "desc">;
  entityType?: string;
  entityId?: string;
  userId?: string;
  field?: string;
  includes?: Relationship["type"][];
};

export class Logs {
  static async findEntityLogs(params: FindEntitiyLogParams, auth: string) {
    const resp = await axios.get<CollectionResult<EntityLogEntity>>(
      "/entity/log" + paramsParser(params),
      { headers: Headers.Bearer(auth) },
    );

    return assertSuccess(resp.data);
  }
}
